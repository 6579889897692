<template>
	<nav class="border-b fixed animated w-full bg-white" :class="{ scrolled: !view.atTopOfPage }">
		<div class="mx-auto px-2 sm:px-6 lg:px-8">
			<div class="relative flex items-center justify-between h-16">
				<div class="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
					<div class="flex-shrink-0 flex items-center mb-2">
						<a href="/"><DashDocsLogo /></a>
					</div>
				</div>
				<div v-if="loggedIn" class="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
					<div class="hidden sm:block sm:ml-6">
						<div class="flex space-x-4">
							<a href="/dashboard" class="buttonprimary">Dashboard</a>
						</div>
					</div>
				</div>
				<div v-if="!loggedIn" class="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
					<div class="hidden sm:block sm:ml-6">
						<div class="flex space-x-4">
							<a href="/login" class="buttonprimary">Client Login</a>
						</div>
					</div>
				</div>
				<div v-if="loggedIn" class="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
					<button class="bg-gray-800 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
						<span class="sr-only">View notifications</span>
						<NotifyIcon class="h-6 w-6" />
					</button>
					<div class="ml-3 relative">
						<div>
							<button
								class="bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
								id="user-menu"
								aria-haspopup="true"
							>
								<span class="inline-flex items-center justify-center h-8 w-8 rounded-full bg-yellow-600">
									<span class="font-medium leading-none text-white">{{ initials }}</span>
								</span>
							</button>
						</div>
					</div>
					<div class="hidden sm:block sm:ml-6">
						<div class="flex space-x-4">
							<a href="/login" class="bg-gray-900 text-white px-3 py-2 rounded-md text-sm font-medium">Log Out</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</nav>
</template>

<script>
import DashDocsLogo from './DashDocsLogo.vue';
import NotifyIcon from './icons/BellIcon.vue';
import { mapGetters } from 'vuex';

export default {
	name: 'Navbar',
	components: {
		NotifyIcon,
		DashDocsLogo,
	},
	data: () => {
		return {
			view: {
				atTopOfPage: true,
			},
		};
	},
	computed: {
		...mapGetters(['loggedIn', 'initials']),
	},
	// a beforeMount call to add a listener to the window
	beforeMount() {
		window.addEventListener('scroll', this.handleScroll);
	},
	methods: {
		// the function to call when the user scrolls, added as a method
		handleScroll() {
			// when the user scrolls, check the pageYOffset
			if (window.pageYOffset > 0) {
				// user is scrolled
				if (this.view.atTopOfPage) this.view.atTopOfPage = false;
			} else {
				// user is at top of page
				if (!this.view.atTopOfPage) this.view.atTopOfPage = true;
			}
		},
	},
};
</script>

<style scoped>
nav {
	z-index: 100;
}

nav.scrolled {
	@apply shadow;
	border-bottom: 0px;
}
</style>