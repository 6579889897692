<template>
	<div class="py-6 max-w-7xl rounded-md mx-auto px-4 sm:px-6 lg:px-8">
		<div class="max-w-7xl mx-auto">
			<div class="">
				<div class="text-base text-primary-800 font-semibold tracking-wide uppercase">Documents to Data</div>
				<div class="text-2xl font-extrabold tracking-tight text-gray-700 sm:text-3xl">Improve Accuracy, Reduce Workloads</div>
				<div class="max-w-5xl sm:text-xl text-gray-500 mt-1">DashDocs uses cloud-based intelligent processing models to replace traditional manual data capture.</div>
			</div>
			<div class="mt-0 sm:mt-6 px-4 grid lg:grid-cols-2">
				<div v-for="item in features" :key="item.name" class="space-y-6 md:space-y-0 md:gap-x-8 md:gap-y-10 flex py-2 items-center max-w-3xl">
					<div class="">
						<component :is="item.icon" class="w-14 rounded-full bg-primary-900 text-white p-3 sm:ml-8" aria-hidden="true" />
					</div>
					<div class="max-w-lg px-3">
						<div class="text-xl leading-6 font-semibold text-gray-700">{{ item.heading }}</div>
						<div class="text-sm sm:text-base text-gray-500 mt-1 text-justify">
							{{ item.description }}
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { DocumentReportIcon, CalculatorIcon, ChevronDoubleRightIcon, BadgeCheckIcon } from '@heroicons/vue/solid';

export default {
	name: 'Features',
	data: () => {
		return {
			features: [
				{ heading: 'Consumption Based Usage', description: "We don't charge upfront fees, you pay per page processed and only pay for what you use.", icon: 'DocumentReportIcon' },
				{
					heading: 'Fast and Scalable',
					description: 'DashDocs has been designed from the ground up to process huge document volumes at high speed.',
					icon: 'ChevronDoubleRightIcon',
				},
				{
					heading: 'No Hidden Fees',
					description: "There's no complicated licencing model. Our transparent pricing and real time reporting means no nasty suprises in your monthly bill.",
					icon: 'CalculatorIcon',
				},
				{
					heading: 'World Class Support',
					description: 'Our customers are provided with dedicated and professional support from the DashDocs development team.',
					icon: 'BadgeCheckIcon',
				},
			],
		};
	},
	components: {
		DocumentReportIcon,
		CalculatorIcon,
		ChevronDoubleRightIcon,
		BadgeCheckIcon,
	},
};
</script>
