import throttle from 'lodash/throttle';

function mounted(el, binding) {
	const callback = binding.value;
	if (binding.modifiers.immediate) {
		callback();
	}
	const throttledScroll = throttle(callback, 500);
	el.addEventListener('scroll', throttledScroll, true);
}

export default {
	mounted,
};
