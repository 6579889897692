import axios from 'axios';

function getDocumentRequestFields(clientId, requestId) {
	const url = `${process.env.VUE_APP_DASHDOCUMENTS_API}Document/GetDocumentRequestFields`;
	const headers = { clientId, requestId };

	return axios({
		method: 'get',
		url,
		headers,
	});
}

function getDocument(clientId, requestId) {
	const url = `${process.env.VUE_APP_DASHDOCUMENTS_API}Document/GetDocument`;
	const headers = { clientId, requestId };

	return axios({
		method: 'get',
		url,
		headers,
	});
}

function saveDocumentRequestFieldReviewValues(clientId, requestId, reviewedFields) {
	const url = `${process.env.VUE_APP_DASHDOCUMENTS_API}Document/SaveDocumentRequestFieldReviewValues`;
	const data = JSON.stringify(reviewedFields);
	const headers = { clientId, requestId };

	return axios({
		method: 'post',
		url,
		data,
		headers,
	});
}

export const documentService = {
	getDocumentRequestFields,
	getDocument,
	saveDocumentRequestFieldReviewValues,
};

export default documentService;
