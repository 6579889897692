<template>
	<div class="border bg-red-500 text-sm text-red-50 p-3 max-w-5xl rounded-md" v-if="errorMessages.length > 0">
		<div v-for="(errorMessage, index) in errorMessages" :key="index">
			<p>{{ errorMessage }}</p>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ErrorMessages',
	props: {
		errorMessages: {
			type: Array,
			required: true,
		},
	},
};
</script>
