<template>
	<div>
		<p v-if="error" class="error">
			{{ error }}
		</p>
		<PDFViewer :requestId="requestId" @error="onError" />
	</div>
</template>

<script>
import PDFViewer from '../components/review/PDFViewer.vue';

export default {
	name: 'DocumentReview',
	components: {
		PDFViewer,
	},
	props: {
		requestId: {
			type: String,
		},
	},
	data() {
		return {
			error: undefined,
		};
	},
	methods: {
		onError(e) {
			this.error = e.text;
		},
	},
};
</script>
