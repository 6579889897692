import axios from 'axios';

function sendEmail(fullName, cellNumber, emailAddress, subject, message) {
	const url = `${process.env.VUE_APP_DASHDOCUMENTS_API}Web/SendEmail`;
	const data = {
		subject: subject,
		content: `Name: ${fullName}\nCellNumber: ${cellNumber}\nEmail: ${emailAddress}\nMessage: ${message}`,
		source: 'DashDocuments.Web',
	};
	const headers = { requestOriginKey: process.env.VUE_APP_DASHDOCUMENTS_REQUEST_ORIGIN_KEY };

	return axios({
		method: 'post',
		url,
		data,
		headers,
	});
}

export const webService = {
	sendEmail,
};

export default webService;
