<template>
	<Navbar />
	<main class="pt-10">
		<router-view />
	</main>
</template>

<script>
import Navbar from './components/Navbar.vue';

export default {
	name: 'App',
	components: {
		Navbar,
	},
};
</script>
