<template>
	<div>
		<PDFData :userId="userId" :clientId="clientId" :requestId="requestId" :url="url" @page-count="onPageCountChanged" @page-focus="onPageFocused" @error="onError">
			<template v-slot:preview="{ pages }">
				<PDFPreview v-show="isPreviewEnabled" class="pdf-viewer__preview" v-bind="{ pages, pageCount, currentPage }" @error="onError" />
			</template>
			<template v-slot:document="{ pages, documentRequestFields }">
				<header class="doc-nav" :class="{ 'preview-enabled': isPreviewEnabled }">
					<div>
						<a @click.prevent.stop="onTogglePreview">
							<PreviewIcon class="h6 w-6" />
						</a>
					</div>
					<PDFPaginator v-model="currentPage" :pageCount="pageCount" @update:model-value="currentPage = $event" />
				</header>
				<PDFDocument
					:class="{ 'preview-enabled': isPreviewEnabled }"
					v-bind="{
						pages,
						documentRequestFields,
						scale,
						currentPage,
						pageCount,
						isPreviewEnabled,
						selectedDocumentRequestFieldId,
					}"
					@update-scale="onUpdateScale"
					@error="onError"
				/>
			</template>
			<template v-slot:extractedText="{ documentRequestFields, documentConfigurationSectionFields }">
				<PDFExtractedText v-bind="{ documentRequestFields, documentConfigurationSectionFields }" @dbl-click="onDblClick" />
			</template>
		</PDFData>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import PreviewIcon from '../icons/PreviewIcon.vue';
import PDFPaginator from './PDFPaginator';
import PDFData from './PDFData';
import PDFPreview from './PDFPreview';
import PDFDocument from './PDFDocument';
import PDFExtractedText from './PDFExtractedText';
import { documentService } from '@/services';

export default {
	name: 'PDFViewer',
	emits: ['error'],
	components: {
		PreviewIcon,
		PDFPaginator,
		PDFData,
		PDFPreview,
		PDFDocument,
		PDFExtractedText,
	},
	props: {
		requestId: {
			type: String,
		},
	},
	computed: {
		...mapGetters(['userId', 'clientId']),
	},
	data() {
		return {
			pages: [],
			documentRequestFields: [],
			documentConfigurationSectionFields: [],
			scale: undefined,
			pageCount: undefined,
			currentPage: 1,
			isPreviewEnabled: false,
			selectedDocumentRequestFieldId: undefined,
			url: undefined,
		};
	},
	methods: {
		onError(e) {
			this.$emit('error', e);
		},
		onUpdateScale(scale) {
			this.scale = scale;
		},
		onPageCountChanged(pageCount) {
			this.pageCount = pageCount;
		},
		onPageFocused(pageNumber) {
			this.currentPage = pageNumber;
		},
		onDblClick(documentRequestFieldId, pageNumber) {
			this.selectedDocumentRequestFieldId = documentRequestFieldId;
			this.currentPage = pageNumber;
		},
		onTogglePreview() {
			this.isPreviewEnabled = !this.isPreviewEnabled;
		},
		getDocument() {
			documentService
				.getDocument(this.clientId, this.requestId)
				.then((response) => {
					if (response.data.result && response.data.data && response.data.data.document && response.data.data.mediaType) {
						this.url = `data:${response.data.data.mediaType};base64,${response.data.data.document}`;
					} else {
						this.$emit('error', { text: 'Failed to retrieve document', response });
					}
				})
				.catch((response) => {
					this.$emit('error', { text: 'Failed to retrieve document', response });
				});
		},
	},
	watch: {
		requestId: {
			handler(requestId) {
				if (requestId) {
					this.selectedDocumentRequestFieldId = undefined;
					window.scrollTo(0, 0);
					this.getDocument();
				}
			},
			immediate: true,
		},
		url() {
			this.currentPage = undefined;
		},
	},
};
</script>
