<template>
	<div class="max-w-screen-xl mx-auto pt-8">
		<h2 class="page-header">Document Stats</h2>
		<ul class="mt-3 grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-2 lg:grid-cols-5">
			<li class="col-span-1 flex shadow-sm rounded-md">
				<div class="flex-shrink-0 flex items-center justify-center w-16 text-white text-xl font-medium rounded-l-md bg-red-800">
					{{ processed }}
				</div>
				<div class="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate">
					<div class="flex-1 px-4 py-2 text-sm truncate">
						<a href="#" class="main-header">Total Processed</a>
						<p class="text-gray-500 text-xs">Last 24 hours</p>
					</div>
				</div>
			</li>
			<li class="col-span-1 flex shadow-sm rounded-md">
				<div class="flex-shrink-0 flex items-center justify-center w-16 text-white text-xl font-medium rounded-l-md bg-purple-600">
					{{ autoApproved }}
				</div>
				<div class="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate">
					<div class="flex-1 px-4 py-2 text-sm truncate">
						<a href="#" class="main-header">Auto Approved</a>
						<p class="text-gray-500 text-xs">Last 24 hours</p>
					</div>
				</div>
			</li>
			<li class="col-span-1 flex shadow-sm rounded-md">
				<div class="flex-shrink-0 flex items-center justify-center w-16 text-white text-xl font-medium rounded-l-md bg-yellow-500">
					{{ inQueue }}
				</div>
				<div class="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate">
					<div class="flex-1 px-4 py-2 text-sm truncate">
						<a href="#" class="main-header">In Queue</a>
						<p class="text-gray-500 text-xs">Still to process</p>
					</div>
				</div>
			</li>
			<li class="col-span-1 flex shadow-sm rounded-md">
				<div class="flex-shrink-0 flex items-center justify-center w-16 text-white text-xl font-medium rounded-l-md bg-green-500">
					{{ reviewed }}
				</div>
				<div class="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate">
					<div class="flex-1 px-4 py-2 text-sm truncate">
						<a href="#" class="main-header">Review Complete</a>
						<p class="text-gray-500 text-xs">Manually Approved</p>
					</div>
				</div>
			</li>
			<li class="col-span-1 flex shadow-sm rounded-md">
				<div class="flex-shrink-0 flex items-center justify-center w-16 text-white text-xl font-medium rounded-l-md bg-green-500">
					{{ pagesProcessed }}
				</div>
				<div class="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate">
					<div class="flex-1 px-4 py-2 text-sm truncate">
						<a href="#" class="main-header">Pages Processed</a>
						<p class="text-gray-500 text-xs">Last 24 hours</p>
					</div>
				</div>
			</li>
		</ul>
	</div>
</template>

<script>
export default {
	name: 'DashboardMain',
	props: {
		processed: {
			type: Number,
		},
		autoApproved: {
			type: Number,
		},
		inQueue: {
			type: Number,
		},
		reviewed: {
			type: Number,
		},
		pagesProcessed: {
			type: Number,
		},
	},
};
</script>
