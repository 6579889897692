<template>
	<div class="items-center" :class="missing ? 'missing-field' : ''">
		<label class="input-label">{{ name }}</label>
		<input type="text" class="input-text" :class="changed ? 'changed-field' : ''" v-model="inputValue" @dblclick="onDblClick" @change="onChange" />
	</div>
</template>

<script>
export default {
	props: {
		documentRequestFieldId: {
			type: Number,
		},
		documentConfigurationSectionFieldId: {
			type: Number,
		},
		name: {
			type: String,
		},
		value: {
			type: String,
		},
		changed: {
			type: Boolean,
		},
		missing: {
			type: Boolean,
		},
	},
	data: function () {
		return {
			inputValue: undefined,
		};
	},
	methods: {
		onDblClick() {
			this.$emit('dbl-click', this.documentRequestFieldId);
		},
		onChange() {
			this.$emit('change', this.documentConfigurationSectionFieldId, this.inputValue);
		},
	},
	watch: {
		value: {
			handler(value) {
				this.inputValue = value;
			},
			immediate: true,
		},
	},
};
</script>
