import { createRouter, createWebHistory } from 'vue-router';
import store from '../store';

import LogIn from '../views/Login.vue';
import Home from '../views/Home.vue';
import About from '../views/About.vue';
import DashDocsDetails from '../views/DashDocsDetails.vue';
import Dashboard from '../views/Dashboard.vue';
import DocumentReview from '../views/DocumentReview.vue';

const routes = [
	{
		path: '/login',
		name: 'LogIn',
		component: LogIn,
		props: false,
		meta: { requiresAuth: false },
	},
	{
		path: '/',
		name: 'Home',
		component: Home,
		props: false,
		meta: { requiresAuth: false },
	},
	{
		path: '/about',
		name: 'About',
		component: About,
		props: false,
		meta: { requiresAuth: false },
	},
	{
		path: '/dashdocsdetails',
		name: 'DashDocsDetails',
		component: DashDocsDetails,
		props: false,
		meta: { requiresAuth: false },
	},
	{
		path: '/dashboard',
		name: 'Dashboard',
		component: Dashboard,
		props: false,
		meta: { requiresAuth: true },
	},
	{
		path: '/documentreview',
		name: 'DocumentReview',
		component: DocumentReview,
		props: (route) => ({ requestId: route.query.requestId }),
		meta: { requiresAuth: true },
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
	scrollBehavior(to, from, savedPosition) {
		return { top: 0 };
	},
});

router.beforeEach((to, from, next) => {
	const requiresAuth = to.meta.requiresAuth;
	if (!requiresAuth) {
		return next();
	}

	const isLoggedIn = store.state.userModule.loggedIn;
	if (!isLoggedIn) {
		return next('/login');
	}

	return next();
});

export default router;
