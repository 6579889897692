<template>
	<div>
		<div class="flex items-center">
			<div @click="onClick">
				<IconArrowRight v-if="!documentField.open" class="w-5 h-5 mt-8" />
				<IconArrowDown v-if="documentField.open" class="w-5 h-5 mt-8" />
			</div>
			<div class="section-header">
				{{ documentField.sectionName }}
			</div>
		</div>
		<div v-show="documentField.open">
			<DocumentSectionChild
				v-for="(field, index) in documentField.fields"
				:key="index"
				:field="field"
				@dbl-click="onDblClick"
				@change="onChange"
			/>
		</div>
	</div>
</template>

<script>
import IconArrowRight from '../icons/ArrowDownIcon.vue';
import IconArrowDown from '../icons/ArrowRightIcon.vue';
import DocumentSectionChild from './DocumentSectionChild.vue';

export default {
	name: 'DocumentSection',
	emits: ['toggle-open', 'dbl-click', 'change', 'delete', 'undelete'],
	components: {
		IconArrowRight,
		IconArrowDown,
		DocumentSectionChild,
	},
	props: {
		documentField: {
			type: Object,
		},
	},
	methods: {
		onClick() {
			this.$emit('toggle-open', this.documentField.sectionName);
		},
		onDblClick(documentRequestFieldId) {
			this.$emit('dbl-click', documentRequestFieldId, this.documentField.sectionName);
		},
		onChange(documentConfigurationSectionFieldId, inputValue) {
			this.$emit('change', documentConfigurationSectionFieldId, inputValue);
		},
	},
};
</script>
