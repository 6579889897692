<template>
	<div class="max-w-screen-xl mx-auto pt-8 text-xl">
		<DocumentQueue :documentRequests="documentRequests" />
	</div>
</template>

<script>
import DocumentQueue from './DocumentQueue.vue';

export default {
	name: 'QueueMain',
	components: {
		DocumentQueue,
	},
	props: {
		documentRequests: {
			type: Array,
			required: true,
		},
	},
};
</script>
