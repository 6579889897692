<template>
	<div class="min-h-screen flex items-center justify-center py-28 px-4 sm:px-6 lg:px-8 overflow-hidden">
		<div class="max-w-md w-full space-y-8">
			<div>
				<h2 class="mt-4 text-2xl lg:text-3xl font-extrabold text-gray-900">Sign in to your account</h2>
			</div>
			<form id="login" class="mt-8 space-y-6" @submit.prevent="submit">
				<div class="rounded-md shadow-sm space-y-4">
					<TextInput
						autocomplete="on"
						:autofocus="true"
						form="login"
						:maxlength="150"
						:minlength="5"
						name="emailAddress"
						placeholder="Email address"
						type="text"
						v-model="emailAddress"
						@update:model-value="emailAddress = $event"
					/>
					<TextInput
						form="login"
						:maxlength="15"
						:minlength="5"
						name="password"
						placeholder="Password"
						type="password"
						v-model="password"
						@update:model-value="password = $event"
					/>
				</div>
				<div>
					<button type="submit" :disabled="submitted" class="buttonprimary px-5 py-3">Sign in</button>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import TextInput from '../components/controls/TextInput.vue';
import { clientService } from '@/services';

export default {
	name: 'LogIn',
	components: {
		TextInput,
	},
	data() {
		return {
			submitted: false,
			emailAddress: null,
			password: null,
		};
	},
	computed: {
		...mapGetters(['loggedIn']),
	},
	watch: {
		loggedIn: {
			immediate: true,
			deep: false,
			handler(loggedIn) {
				if (loggedIn) {
					this.$router.push('/dashboard');
				}
			},
		},
	},
	methods: {
		submit(event) {
			event.preventDefault();
			this.errorMessages = [];
			this.submitted = true;

			clientService
				.authenticateUser(this.emailAddress, this.password)
				.then((response) => {
					this.submitted = false;

					if (response.data.result && response.data.data && response.data.data.webToken) {
						this.$store.dispatch('loginSuccess', {
							user: response.data.data,
						});
					}
				})
				.catch((error) => {
					this.submitted = false;
				});
		},
	},
	created() {
		this.$store.dispatch('loginClear');
	},
};
</script>
