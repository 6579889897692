<template>
	<div @click="onPageFocused" v-visible.once="drawPage" :class="{ focused: isPageFocused }" class="pdf-thumbnail">
		<img v-if="src" :src="src" class="shadow-sm" />
		<div v-else class="placeholder">
			<div class="content">Loading</div>
		</div>
		<span class="page-number">{{ pageNumber }}</span>
	</div>
</template>

<script>
import visible from '@/directives/visible';

export default {
	name: 'PDFThumbnail',
	emits: ['page-focus','error'],
	props: {
		page: {
			type: Object, // instance of PDFPageProxy returned from pdf.getPage
			required: true,
		},
		isPageFocused: {
			type: Boolean,
			default: false,
		},
	},
	directives: {
		visible,
	},
	data() {
		return {
			src: undefined,
			renderTask: undefined,
		};
	},
	computed: {
		viewport() {
			return this.page.getViewport({ scale: 1.0 });
		},
		pageNumber() {
			return this.page.pageNumber;
		},
	},
	methods: {
		onPageFocused() {
			this.$emit('page-focus', this.pageNumber);
		},
		drawPage() {
			if (this.renderTask) {
				return;
			}

			const { viewport } = this;
			const canvas = document.createElement('canvas');
			const canvasContext = canvas.getContext('2d');
			const renderContext = { canvasContext, viewport };
			canvas.height = viewport.height;
			canvas.width = viewport.width;

			this.renderTask = this.page.render(renderContext);
			this.renderTask.promise
				.then(() => {
					this.src = canvas.toDataURL();
					// Zeroing the width and height causes Firefox to release graphics
					// resources immediately, which can greatly reduce memory consumption.
					canvas.width = 0;
					canvas.height = 0;
				})
				.then(() => {})
				.catch((response) => {
					this.destroyRenderTask();
					this.$emit('error', {
						response,
						page: this.page,
						text: `Failed to render thumbnail ${this.pageNumber}`,
					});
				});
		},
		destroyPage(_newPage, page) {
			if (page) {
				page._destroy();
			}

			this.destroyRenderTask();
		},
		destroyRenderTask() {
			if (!this.renderTask) {
				return;
			}
			this.renderTask.cancel();
			delete this.renderTask;
		},
		updateVisibility() {
			this.emitter.emit('update-visibility');
		},
	},
	watch: {
		page: 'destroyPage',
		src: 'updateVisibility',
	},
	beforeUnmount() {
		this.destroyPage(undefined, this.page);
	},
};
</script>
