<script>
import { h } from 'vue';
import range from 'lodash/range';
import { documentService } from '@/services';
import { useToast } from 'vue-toastification';
import * as pdfjs from 'pdfjs-dist/webpack'

export default {
	name: 'PDFData',
	emits: ['error', 'page-count', 'page-focus'],
	props: {
		userId: {
			type: Number,
		},
		clientId: {
			type: String,
		},
		requestId: {
			type: String,
		},
		url: {
			type: String,
		},
	},
	data() {
		return Object.assign(this.getDefaults(), {
			pdf: undefined,
		});
	},
	watch: {
		url: {
			handler(url) {
				if (url) {
					this.getDocument(url)
						.then((pdf) => (this.pdf = pdf))
						.catch((response) => {
							this.$emit('error', { text: 'Failed to retrieve PDF', response });
						});
				}
			},
			immediate: true,
		},
		pdf(pdf, oldPdf) {
			if (!pdf) {
				return;
			}

			if (oldPdf) {
				Object.assign(this, this.getDefaults());
			}

			this.$emit('page-count', this.pageCount);
			this.fetchPages();
		},
		requestId: {
			handler(requestId) {
				if (requestId) {
					this.getDocumentRequestFields();
				}
			},
			immediate: true,
		},
	},
	computed: {
		pageCount() {
			return this.pdf ? this.pdf.numPages : 0;
		},
	},
	methods: {
		getDocument(url) {
			return pdfjs.getDocument(url).promise;
		},
		getPages(pdf, pageCount) {
			const allPages = range(1, pageCount + 1).map((number) => pdf.getPage(number));
			return Promise.all(allPages);
		},
		getDefaults() {
			return {
				pages: [],
				documentRequestFields: [],
				documentConfigurationSectionFields: [],
			};
		},
		fetchPages() {
			if (!this.pdf) {
				return;
			}

			if (this.pageCount > 0 && this.pages.length === this.pageCount) {
				return;
			}

			this.getPages(this.pdf, this.pageCount)
				.then((pages) => {
					this.pages = pages.slice();
				})
				.catch((response) => {
					this.$emit('error', { text: 'Failed to retrieve pages', response });
				});
		},
		getDocumentRequestFields() {
			documentService
				.getDocumentRequestFields(this.clientId, this.requestId)
				.then((response) => {
					if (response.data.result) {
						this.documentRequestFields = response.data.data.documentRequestFields.sort((a,b) => a.documentConfigurationSectionFieldId - b.documentConfigurationSectionFieldId);
						this.documentConfigurationSectionFields = response.data.data.documentConfigurationSectionFields.sort((a,b) => a.documentConfigurationSectionId - b.documentConfigurationSectionId);
					}
				})
				.catch((response) => {
					this.$emit('error', { text: 'Failed to retrieve fields', response });
				});
		},
		onSaveChanges(reviewedFields) {
			documentService
				.saveDocumentRequestFieldReviewValues(this.clientId, this.requestId, reviewedFields)
				.then((response) => {
					if (response.data.result) {
						useToast().success('Changes saved');
					} else {
						this.$emit('error', { text: 'Failed save changes', response });
					}
				})
				.catch((response) => {
					this.$emit('error', { text: 'Failed save changes', response });
				});
		},
	},
	created() {
		this.emitter.on('pages-fetch', this.fetchPages);
		this.emitter.on('save-changes', this.onSaveChanges);
	},
	render() {
		return h('div', [
			this.$slots.preview({
				pages: this.pages,
			}),
			this.$slots.document({
				pages: this.pages,
				documentRequestFields: this.documentRequestFields,
			}),
			this.$slots.extractedText({
				documentRequestFields: this.documentRequestFields,
				documentConfigurationSectionFields: this.documentConfigurationSectionFields,
			}),
		]);
	},
};
</script>
