<template>
<div class="max-w-6xl py-12 mx-auto space-y-4">
<div class="text-3xl font-semibold">How DashDocs Works</div>
<!-- <div>DashDocs is a software as a service (SAAS) product which turns documents and images to data 
	which essentially turns documents (PDFs, etc.) into data through an OCR process and extracts configured values. 
	For example, a business could send a banking proof of payment PDF document (from any of the major banks) to the Dash Documents API and a JSON data payload is returned with the extracted values. What values get extracted from a document is 
	configurable, using the proof of payment example it could be payment date, amount, account number, payment reference, etc., as long as the value is present in the document.It is primarily a system-to-system software as a service (SAAS) 
	solution that can automated (replace) any kind of manual data capture process,from a known document format and get the data into a client’s system through integration which we can assist with or can be done internally.
</div>
<div> 
That is a very brief overview of Dash Documents, there is obviously a lot more technical detail and complexity behind the scenes. What we would like to find out is, if you work with or know of any businesses that may be interested in using 
Dash Documents, and if you would be happy to make the introductions? Our billing is typically per page processed so we are ideally looking for potential new clients that would use the system at higher volumes. Steve can discuss how the business 
arrangement could work. As a next step, if you are interested, I can give you a brief demo and also go into more technical detail. I am no salesman, so it will be about as exciting as watching paint dry but it could give you some insight into 
how\where Dash Documents can be used. The banking proof of payment is just an example, we have also done more complex documents like insurance schedules (20+ pages with 50+ fields extracted).
</div> -->
</div>
</template>

<script>
export default {
	name: 'DashDocsDetails',
};
</script>
