<template>
	<div class="relative bg-primary-900 overflow-hidden -mt-4">
		<div class="hidden sm:block sm:absolute sm:inset-0" aria-hidden="true">
			<svg
				class="absolute bottom-0 right-0 transform translate-x-1/2 mb-48 text-primary-600 lg:top-0 lg:mt-28 lg:mb-0 xl:transform-none xl:translate-x-0"
				width="364"
				height="384"
				viewBox="0 0 364 384"
				fill="none"
			>
				<defs>
					<pattern id="eab71dd9-9d7a-47bd-8044-256344ee00d0" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
						<rect x="0" y="0" width="4" height="4" fill="currentColor" />
					</pattern>
				</defs>
				<rect width="364" height="384" fill="url(#eab71dd9-9d7a-47bd-8044-256344ee00d0)" />
			</svg>
		</div>
		<div class="relative pt-4 pb-12 sm:pb-18">
			<main class="mt-12 sm:mt-12">
				<div class="mx-auto max-w-7xl">
					<div class="lg:grid lg:grid-cols-12 lg:gap-8">
						<div class="px-4 sm:px-6 sm:text-center md:max-w-5xl md:mx-auto lg:col-span-6 lg:text-left lg:flex lg:items-center">
							<div>
								<!-- <a href="/dashdocsdetails" class="inline-flex items-center text-white bg-gray-900 rounded-full p-1 pr-2 sm:text-base lg:text-sm xl:text-base hover:text-gray-200">
									<span class="px-3 py-0.5 text-white text-xs font-semibold leading-5 uppercase tracking-wide bg-primary-900 rounded-full">Learn More</span>
									<span class="ml-4 text-sm">How DashDocs Works</span>
								</a> -->
								<h1 class="mt-4 text-4xl tracking-tight font-extrabold text-white sm:mt-5 sm:leading-none lg:mt-6 lg:text-5xl xl:text-6xl">
									<span class="md:block">A better way to</span>
									{{ ' ' }}
									<span class="text-primary-300 md:block">process documents</span>
								</h1>
								<p class="mt-3 text-base text-gray-200 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
									Reduce data capture errors and speed up integration of your critical data held in physical documents or scans.
								</p>
								<div class="flex gap-5 mt-8">
									<button
										@click="scrollToContact('DashDocs Demo Enquiry')"
										class="bg-yellow-600 rounded text-primary-50 px-5 py-3"
									>
										Book a Demo
									</button>
									<button @click="scrollToContact('DashDocs Enquiry')" class="buttonprimary px-5 py-3">Contact Us</button>
								</div>
							</div>
						</div>
						<div class="mt-16 sm:mt-24 lg:mt-6 lg:col-span-6 ml-16">
							<img src="@/assets/images/dashdocs_hero.png" alt="" />
						</div>
					</div>
				</div>
			</main>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Hero',
	methods: {
		scrollToContact(subject) {
			this.emitter.emit('scroll-to-contact', subject);
		},
	},
};
</script>
