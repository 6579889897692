<template>
	<div class="mx-auto px-4 sm:px-6 lg:px-8">
		<p v-if="error" class="error">
			{{ error }}
		</p>
		<DashboardMain
			v-if="loggedIn && dashboardData"
			:processed="dashboardData.processed"
			:autoApproved="dashboardData.autoApproved"
			:inQueue="dashboardData.inQueue"
			:reviewed="dashboardData.reviewed"
			:pagesProcessed="dashboardData.pagesProcessed"
		/>
		<UploadMain v-if="loggedIn" :clientId="clientId" @error="onError" />
		<QueueMain v-if="loggedIn && dashboardData" :documentRequests="dashboardData.documentRequests" />
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { clientService } from '@/services';
import DashboardMain from '../components/dashboard/DashboardMain.vue';
import UploadMain from '../components/upload/UploadMain.vue';
import QueueMain from '../components/queue/QueueMain.vue';

export default {
	name: 'Dashboard',
	emits: ['error'],
	components: {
		DashboardMain,
		UploadMain,
		QueueMain,
	},
	computed: {
		...mapGetters(['loggedIn', 'userId', 'clientId']),
	},
	data: () => {
		return {
			apiPolling: undefined,
			dashboardData: undefined,
			error: undefined,
		};
	},
	watch: {
		clientId: {
			handler(clientId) {
				if (clientId) {
					this.getClientDashboardValues(clientId, 99);

					this.apiPolling = setInterval(() => {
						this.getClientDashboardValues(clientId, 99);
					}, 15000);
				}
			},
			immediate: true,
		},
	},
	methods: {
		getClientDashboardValues(clientId, maxDocumentAgeInDays) {
			clientService
				.getClientDashboardValues(clientId, maxDocumentAgeInDays)
				.then((response) => {
					if (response.data.result && response.data.data) {
						this.dashboardData = response.data.data;
					}
				})
				.catch((response) => {
					this.$emit('error', { text: 'Failed to retrieve pages', response });
				});
		},
		onError(e) {
			this.error = e.text;
		},
	},
	beforeUnmount() {
		clearInterval(this.apiPolling);
	},
};
</script>
