<template>
	<div>
		<div class="items-center pt-2 pl-6">
			<StandardInput
				:documentRequestFieldId="field.documentRequestFieldId"
				:documentConfigurationSectionFieldId="field.documentConfigurationSectionFieldId"
				:name="field.name"
				:value="field.value"
				:changed="field.changed"
				:missing="field.missing"
				@dbl-click="onDblClick"
				@change="onChange"
			/>
		</div>
	</div>
</template>

<script>
import StandardInput from '@/components/controls/StandardInput.vue';

export default {
	name: 'DocumentSectionChild',
	emits: ['dbl-click', 'change', 'delete', 'undelete'],
	components: {
		StandardInput,
	},
	props: {
		field: {
			type: Object,
		},
	},
	methods: {
		onDblClick(documentRequestFieldId) {
			this.$emit('dbl-click', documentRequestFieldId);
		},
		onChange(documentConfigurationSectionFieldId, inputValue) {
			this.$emit('change', documentConfigurationSectionFieldId, inputValue);
		},
	},
};
</script>
