<template>
	<ScrollingDocument class="pdf-preview" @pages-fetch="onPagesFetch" v-bind="{ pages, currentPage }" v-slot="{ page, isPageFocused }">
		<PDFThumbnail v-bind="{ page, isPageFocused }" @error="onError" @page-focus="onPageFocused" />
	</ScrollingDocument>
</template>

<script>
import ScrollingDocument from './ScrollingDocument';
import PDFThumbnail from './PDFThumbnail';

export default {
	name: 'PDFPreview',
	components: {
		ScrollingDocument,
		PDFThumbnail,
	},
	props: {
		pages: {
			required: true,
		},
		pageCount: {
			type: Number,
			default: 0,
		},
		currentPage: {
			type: Number,
			default: 1,
		},
	},
	methods: {
		onPagesFetch() {
			this.emitter.emit('pages-fetch');
		},
		onPageFocused(pageNumber) {
			this.$parent.$emit('page-focus', pageNumber);
		},
		onError(e) {
			this.$parent.$emit('error', e);
		},
	},
};
</script>
