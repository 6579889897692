<template>
	<div class="scrolling-document" v-scroll.immediate="updateScrollBounds">
		<ScrollingPage
			v-for="page in pages"
			:key="page.pageNumber"
			v-bind="{ page, currentPage, clientHeight, scrollTop }"
			v-slot="{ isPageFocused, isElementFocused }"
			@page-jump="onPageJump"
		>
			<div class="scrolling-page">
				<slot v-bind="{ page, isPageFocused, isElementFocused }"></slot>
			</div>
		</ScrollingPage>
		<div v-visible="fetchPages" class="observer"></div>
	</div>
</template>

<script>
import scroll from '@/directives/scroll';
import visible from '@/directives/visible';
import ScrollingPage from './ScrollingPage';

export default {
	name: 'ScrollingDocument',
	emits: ['pages-fetch','page-jump','pages-reset'],
	components: {
		ScrollingPage,
	},
	directives: {
		visible,
		scroll,
	},
	props: {
		pages: {
			required: true,
		},
		currentPage: {
			type: Number,
			default: 1,
		},
	},
	data() {
		return {
			scrollTop: 0,
			clientHeight: 0,
		};
	},
	computed: {
		pagesLength() {
			return this.pages.length;
		},
	},
	methods: {
		fetchPages() {
			this.$emit('pages-fetch');
		},
		onPageJump(scrollTop) {
			this.$emit('page-jump', scrollTop);
		},
		updateScrollBounds() {
			const { scrollTop, clientHeight } = this.$el;
			this.scrollTop = scrollTop;
			this.clientHeight = clientHeight;
		},
	},
	watch: {
		pagesLength(count, oldCount) {
			if (oldCount === 0) {
				this.$emit('pages-reset');
			}
		},
	},
};
</script>
