<template>
	<div class="pdf-document">
		<ScrollingDocument
			v-bind="{ pages, pageCount, currentPage }"
			v-slot="{ page, isPageFocused, isElementFocused }"
			:enable-page-jump="true"
			@page-jump="onPageJump"
			@pages-fetch="onPagesFetch"
			@pages-reset="updateScale"
		>
			<PDFPage
				v-bind="{
					page,
					documentRequestFields,
					scale,
					isPageFocused,
					isElementFocused,
					selectedDocumentRequestFieldId,
				}"
				@page-focus="onPageFocused"
				@error="onError"
			/>
		</ScrollingDocument>
	</div>
</template>

<script>
import ScrollingDocument from './ScrollingDocument';
import PDFPage from './PDFPage';

export default {
	name: 'PDFDocument',
	emits: ['update-scale'],
	components: {
		ScrollingDocument,
		PDFPage,
	},
	props: {
		pages: {
			required: true,
		},
		documentRequestFields: {
			type: Array,
			required: true,
		},
		scale: {
			type: Number,
			default: 1.0,
		},
		currentPage: {
			type: Number,
			default: 1,
		},
		pageCount: {
			type: Number,
			default: 0,
		},
		isPreviewEnabled: {
			type: Boolean,
			default: false,
		},
		selectedDocumentRequestFieldId: {
			type: Number,
			default: undefined,
		},
	},
	data() {
		return {
			resizeObserver: undefined,
		};
	},
	computed: {
		defaultViewport() {
			if (!this.pages.length) {
				return { width: 0, height: 0 };
			}

			const [page] = this.pages;
			return page.getViewport({ scale: 1.0 });
		},
	},
	methods: {
		pageWidthScale() {
			const { defaultViewport, $el } = this;
			if (!defaultViewport.width || defaultViewport.width <= 0) {
				return 0;
			}

			return $el.clientWidth / defaultViewport.width;
		},
		updateScale() {
			var scale = this.pageWidthScale();
			this.$emit('update-scale', scale);
		},
		onPageJump(scrollTop) {
			this.$el.scrollTop = scrollTop;
		},
		onPagesFetch() {
			this.emitter.emit('pages-fetch');
		},
		onPageFocused(pageNumber) {
			this.$parent.$emit('page-focus', pageNumber);
		},
		onError(e) {
			this.$parent.$emit('error', e);
		},
	},
	watch: {
		isPreviewEnabled: 'updateScale',
	},
	mounted() {
		this.resizeObserver = new ResizeObserver(this.updateScale);
		this.resizeObserver.observe(this.$el);
		this.updateScale();
	},
	beforeUnmount() {
		this.resizeObserver.unobserve(this.$el);
	},
};
</script>
