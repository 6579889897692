const user = JSON.parse(localStorage.getItem('user'));
const state = user ? { loggedIn: true, user } : { loggedIn: false, user: null };

const mutations = {
	loginSuccess(state, user) {
		state.loggedIn = true;
		state.user = user;
	},
	loginClear(state) {
		state.loggedIn = false;
		state.user = null;
	},
};

const actions = {
	loginSuccess({ commit }, { user }) {
		localStorage.setItem('user', JSON.stringify(user));
		commit('loginSuccess', user);
	},
	loginClear({ commit }) {
		window.localStorage.clear();
		commit('loginClear');
	},
};

const getters = {
	loggedIn: (state) => state.loggedIn,
	userId: (state) => (state.loggedIn && state.user ? state.user.userId : null),
	clientId: (state) => (state.loggedIn && state.user ? state.user.clientId : null),
	fullName: (state) => (state.loggedIn && state.user ? state.user.fullName : null),
	initials: (state) => (state.loggedIn && state.user ? state.user.initials : null),
	emailAddress: (state) => (state.loggedIn && state.user ? state.user.emailAddress : null),
	isAdmin: (state) => (state.loggedIn && state.user ? state.user.isAdmin : 0),
};

export const userModule = {
	state,
	mutations,
	actions,
	getters,
};

export default userModule;
