<template>
	<div class="min-h-screen overflow-hidden">
		<main>
			<!-- Contact section -->
			<section class="relative mt-10" aria-labelledby="contactHeading">
				<div class="absolute w-full h-1/2" aria-hidden="true"></div>
				<div class="max-w-7xl mx-auto border rounded-lg overflow-auto shadow">
					<div class="relative bg-white">
						<h2 id="contactHeading" class="sr-only">Contact us</h2>
						<div class="grid grid-cols-1 lg:grid-cols-3">
							<!-- Contact information -->
							<div class="relative overflow-hidden py-10 px-6 bg-gradient-to-b from-primary-800 to-primary-900 sm:px-10 xl:p-12">
								<!-- Decorative angle backgrounds -->
								<div class="absolute inset-0 pointer-events-none sm:hidden" aria-hidden="true">
									<svg
										class="absolute inset-0 w-full h-full"
										width="343"
										height="388"
										viewBox="0 0 343 388"
										fill="none"
										preserveAspectRatio="xMidYMid slice"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path d="M-99 461.107L608.107-246l707.103 707.107-707.103 707.103L-99 461.107z" fill="url(#linear1)" fill-opacity=".1" />
										<defs>
											<linearGradient id="linear1" x1="254.553" y1="107.554" x2="961.66" y2="814.66" gradientUnits="userSpaceOnUse">
												<stop stop-color="#fff"></stop>
												<stop offset="1" stop-color="#fff" stop-opacity="0"></stop>
											</linearGradient>
										</defs>
									</svg>
								</div>
								<div class="hidden absolute top-0 right-0 bottom-0 w-1/2 pointer-events-none sm:block lg:hidden" aria-hidden="true">
									<svg
										class="absolute inset-0 w-full h-full"
										width="359"
										height="339"
										viewBox="0 0 359 339"
										fill="none"
										preserveAspectRatio="xMidYMid slice"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path d="M-161 382.107L546.107-325l707.103 707.107-707.103 707.103L-161 382.107z" fill="url(#linear2)" fill-opacity=".1" />
										<defs>
											<linearGradient id="linear2" x1="192.553" y1="28.553" x2="899.66" y2="735.66" gradientUnits="userSpaceOnUse">
												<stop stop-color="#fff"></stop>
												<stop offset="1" stop-color="#fff" stop-opacity="0"></stop>
											</linearGradient>
										</defs>
									</svg>
								</div>
								<div class="hidden absolute top-0 right-0 bottom-0 w-1/2 pointer-events-none lg:block" aria-hidden="true">
									<svg
										class="absolute inset-0 w-full h-full"
										width="160"
										height="678"
										viewBox="0 0 160 678"
										fill="none"
										preserveAspectRatio="xMidYMid slice"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path d="M-161 679.107L546.107-28l707.103 707.107-707.103 707.103L-161 679.107z" fill="url(#linear3)" fill-opacity=".1" />
										<defs>
											<linearGradient id="linear3" x1="192.553" y1="325.553" x2="899.66" y2="1032.66" gradientUnits="userSpaceOnUse">
												<stop stop-color="#fff"></stop>
												<stop offset="1" stop-color="#fff" stop-opacity="0"></stop>
											</linearGradient>
										</defs>
									</svg>
								</div>
								<h3 class="text-lg font-medium text-white">Contact information</h3>
								<p class="mt-6 text-base text-blue-50 max-w-3xl">We'd love to hear from you. We aim to answer any queries as quickly as possible.</p>
								<dl class="mt-8 space-y-6">
									<dt><span class="sr-only">Email</span></dt>
									<dd class="flex text-base text-blue-50">
										<!-- Heroicon name: outline/mail -->
										<svg class="flex-shrink-0 w-6 h-6 text-blue-200" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
											<path
												stroke-linecap="round"
												stroke-linejoin="round"
												stroke-width="2"
												d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
											/>
										</svg>
										<span class="ml-3">support@dashdocuments.com</span>
									</dd>
									<dt><span class="sr-only">Office Address</span></dt>
									<dd class="flex text-base text-blue-50">
										<!-- Heroicon name: outline/mail -->
										<svg class="flex-shrink-0 w-6 h-6 text-blue-200" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
											<path
												stroke-linecap="round"
												stroke-linejoin="round"
												stroke-width="2"
												d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
											/>
										</svg>
										<span class="ml-3"
											>3 Abrey Rd<br />
											Kloof<br />
											3640 <br />
											South Africa</span
										>
									</dd>
								</dl>
							</div>
							<!-- Contact form -->
							<div class="py-10 px-6 sm:px-10 lg:col-span-2 xl:p-12">
								<h3 class="text-lg font-medium text-gray-900">Send us a message</h3>
								<form id="contact" class="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8" @submit.prevent="submit">
									<div class="col-span-6 lg:col-span-3">
										<TextInput
											autocomplete="on"
											form="contact"
											:maxlength="150"
											:minlength="1"
											name="fullName"
											placeholder="Full Name"
											type="text"
											v-model="fullName"
											@update:model-value="fullName = $event"
											class="col-span-6 sm:col-span-3"
										/>
									</div>
									<div class="col-span-6 lg:col-span-3">
										<TextInput
											autocomplete="on"
											form="contact"
											:maxlength="150"
											:minlength="1"
											name="cellNumber"
											placeholder="Mobile Number"
											type="text"
											v-model="cellNumber"
											@update:model-value="cellNumber = $event"
											class="col-span-6 sm:col-span-3"
										/>
									</div>
									<div class="col-span-6 lg:col-span-3">
										<TextInput
											autocomplete="on"
											form="contact"
											:maxlength="150"
											:minlength="5"
											name="emailAddress"
											placeholder="Email Address"
											type="email"
											v-model="emailAddress"
											@update:model-value="emailAddress = $event"
											class="col-span-6"
										/>
									</div>
									<div class="col-span-6 lg:col-span-3">
										<TextInput
											autocomplete="on"
											form="contact"
											:maxlength="150"
											:minlength="1"
											name="subject"
											placeholder="Subject"
											type="text"
											v-model="subject"
											@update:model-value="subject = $event"
											class="col-span-6 sm:col-span-3"
										/>
									</div>
									<div class="col-span-6 lg:col-span-3">
										<TextInput
											autocomplete="on"
											form="contact"
											:maxlength="8000"
											:minlength="1"
											name="message"
											placeholder="Message"
											type="text"
											v-model="message"
											@update:model-value="message = $event"
											class="col-span-6 sm:col-span-3"
										/>
									</div>
									<div>
										<button type="submit" :disabled="submitted" :class="{ 'opacity-50 cursor-not-allowed': submitted }" class="buttonprimary px-5 py-3">Submit</button>
									</div>
									<div>
										<ErrorMessages :errorMessages="errorMessages" />
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</section>
		</main>
	</div>
</template>
<script>
import TextInput from '@/components/controls/TextInput.vue';
import ErrorMessages from '@/components/controls/ErrorMessages.vue';
import { webService } from '@/services';
import { useToast } from 'vue-toastification';

export default {
	name: 'Contact',
	inject: ['appInsights'],
	components: {
		TextInput,
		ErrorMessages,
	},
	props: {
		defaultSubject: {
			type: String,
			default: '',
		},
	},
	data: () => {
		return {
			submitted: false,
			errorMessages: [],
			fullName: null,
			emailAddress: null,
			cellNumber: null,
			subject: null,
			message: null,
		};
	},
	methods: {
		submit(event) {
			event.preventDefault();
			this.errorMessages = [];
			this.submitted = true;

			webService
				.sendEmail(this.fullName, this.cellNumber, this.emailAddress, this.subject, this.message)
				.then((response) => {
					this.submitted = false;
					if (response.data.result) {
						useToast().success('Message sent');
					} else {
						this.errorMessages[0] = response.data.message;
					}
				})
				.catch((error) => {
					this.submitted = false;
					if (error.response && error.response.data && error.response.data.errors) {
						let errorProperties = Object.keys(error.response.data.errors);
						for (let i = 0; i < errorProperties.length; i += 1) {
							let errorArray = error.response.data.errors[errorProperties[i]];
							for (let j = 0; j < errorArray.length; j += 1) {
								this.errorMessages.push(errorArray[j]);
							}
						}
					}
					this.appInsights.trackException({
						exception: new Error(`Message send failed:${error.message}`),
					});
				});
		},
	},
	watch: {
		defaultSubject: {
			handler(defaultSubject) {
				this.subject = defaultSubject;
			},
			immediate: true,
		},
	},
};
</script>
