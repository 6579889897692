<template>
	<div class="max-w-screen-xl mx-auto pt-8 text-xl">
		<h2 class="text-gray-500 text-base font-medium uppercase tracking-wide">Document Upload</h2>
		<file-pond
			name="File"
			ref="upload"
			label-idle="Drop files here..."
			:allow-multiple="false"
			accepted-file-types="application/pdf"
			:server="server"
			v-on:error="handleOnError"
			v-on:processfileprogress="handleOnProcessFileProgress"
			class="mt-3 border border-gray-300 rounded-lg bg-gray-50 shadow-sm"
		/>
	</div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import vueFilePond from 'vue-filepond';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';

const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview);

export default {
	name: 'UploadMain',
	emits: ['error'],
	components: {
		FilePond,
	},
	props: {
		clientId: {
			type: String,
		},
	},
	data() {
		return {
			server: {
				process: this.process,
			},
		};
	},
	methods: {
		process(fieldName, file, metadata, load, error, progress, abort) {
			const requestId = uuidv4();
			const clientId = this.clientId;
			const url = `${process.env.VUE_APP_DASHDOCUMENTS_API}Document/Upload`;
			const formData = new FormData();
			formData.append(fieldName, file, file.name);

			const request = new XMLHttpRequest();
			request.open('POST', url);
			request.setRequestHeader('clientId', clientId);
			request.setRequestHeader('requestId', requestId);
			request.setRequestHeader('documentType', 'Unknown');

			request.upload.onprogress = (e) => {
				progress(e.lengthComputable, e.loaded, e.total);
			};

			request.onload = () => {
				if (request.status >= 200 && request.status < 300) {
					load(request.responseText);
				} else {
					error(request);
				}
			};

			request.send(formData);

			return {
				abort: () => {
					request.abort();
					abort();
				},
			};
		},
		handleOnProcessFileProgress(file, progress) {
			this.progress = Math.round(progress * 100);
		},
		handleOnError(error) {
			this.$emit('error', error);
		},
	},
};
</script>
