import axios from 'axios';

function authenticateUser(emailAddress, password) {
	const url = `${process.env.VUE_APP_DASHDOCUMENTS_API}Client/AuthenticateUser`;
	const data = JSON.stringify({ emailAddress, password });
	const headers = { requestOriginKey: process.env.VUE_APP_DASHDOCUMENTS_REQUEST_ORIGIN_KEY };

	return axios({
		method: 'post',
		url,
		data,
		headers,
	});
}

function getClientDashboardValues(clientId, maxDocumentAgeInDays) {
	const url = `${process.env.VUE_APP_DASHDOCUMENTS_API}Client/GetClientDashboardValues`;
	const headers = { requestOriginKey: process.env.VUE_APP_DASHDOCUMENTS_REQUEST_ORIGIN_KEY, clientId, maxDocumentAgeInDays };

	return axios({
		method: 'get',
		url,
		headers,
	});
}

export const clientService = {
	authenticateUser,
	getClientDashboardValues,
};

export default clientService;
