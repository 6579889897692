<template>
	<div class="min-h-screen flex items-center justify-center py-28 px-4 sm:px-6 lg:px-8 overflow-hidden">
		<div class="max-w-md w-full space-y-8">
			<!-- <div>
				<h2 class="mt-4 text-2xl lg:text-3xl font-extrabold text-gray-900">About Us</h2>
			</div> -->
    </div>
  </div>
</template>

<script>
export default {
	name: 'About',
};
</script>
