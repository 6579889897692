<template>
	<div class="pdf-paginator">
		<template v-if="pageCount">
			<input :value="modelValue" @input="onUpdate($event)" @keydown="onKeyDown($event)" min="1" :max="pageCount" type="number" class="input-text mr-2" />
			/ <span class="pl-2">{{ pageCount }}</span>
		</template>
		<input v-else type="number" />
	</div>
</template>

<script>
export default {
	name: 'PDFPaginator',
	emits: ['update:modelValue'],
	props: {
		modelValue: {
			type: Number,
		},
		pageCount: {
			type: Number,
		},
	},
	methods: {
		onUpdate(event) {
			const pageNumber = parseInt(event.target.value, 10);
			if (pageNumber >= 1 && pageNumber <= this.pageCount) {
				this.$emit('update:modelValue', pageNumber);
			}
		},
		onKeyDown(event) {
			let keyCode = event.keyCode ? event.keyCode : event.which;
			if ((keyCode < 48 || keyCode > 57) && keyCode !== 8) {
				event.preventDefault();
			}
		},
	},
	watch: {
		pageCount() {
			this.$emit('update:modelValue', 1);
		},
	},
};
</script>
