import { createApp } from 'vue';
import App from './App.vue';
import store from './store';
import router from './router';
import '@/assets/index.css';
import axios from 'axios';
import VueAxios from 'vue-axios';
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import mitt from 'mitt';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

const appInsights = new ApplicationInsights({
	config: {
		connectionString: process.env.VUE_APP_DASHDOCUMENTS_APPLICATION_INSIGHTS_CONNECTION_STRING,
	},
});
appInsights.loadAppInsights();
appInsights.trackPageView();

axios.interceptors.request.use(
	(config) => {
		config.headers.Accept = 'application/json';
		config.headers['Content-Type'] = 'application/json';

		const user = JSON.parse(localStorage.getItem('user'));
		if (user && user.webToken) {
			config.headers.Authorization = `Bearer ${user.webToken}`;
			return config;
		}
		return config;
	},
	(error) => {
		Promise.reject(error);
	}
);

axios.interceptors.response.use(
	(response) => response,
	(error) => {
		if (error && error.response && error.response.status === 401) {
			store.dispatch('loginClear');
			router.push('/');
		}
		return Promise.reject(error);
	}
);

const toastOptions = {
	position: 'top-center',
	timeout: 1500, // milliseconds
};

const emitter = mitt();
const app = createApp(App);
app.config.globalProperties.emitter = emitter;

app.use(router);
app.use(store);
app.use(VueAxios, axios);
app.use(Toast, toastOptions);
app.provide('appInsights', appInsights);
app.mount('#app');
