<template>
	<div class="pdf-extracted-text">
		<DocumentSummary :documentFields="documentFields" @dbl-click="onDblClick" @save-changes="onClickSave" @cancel-changes="onClickCancel" />
	</div>
</template>

<script>
import _ from 'lodash';
import DocumentSummary from './DocumentSummary.vue';

export default {
	name: 'PDFExtractedText',
	emits: ['dbl-click'],
	components: {
		DocumentSummary,
	},
	props: {
		documentRequestFields: {
			type: Array,
			required: true,
		},
		documentConfigurationSectionFields: {
			type: Array,
			required: true,
		},
	},
	data() {
		return {
			documentFields: [],
		};
	},
	methods: {
		onDblClick(documentRequestFieldId, sectionName) {
			const sectionFields = _.filter(this.documentFields, { sectionName: sectionName })[0];
			if (sectionFields) {
				const field = _.filter(sectionFields.fields, { documentRequestFieldId: documentRequestFieldId })[0];
				if (field) {
					this.$emit('dbl-click', field.documentRequestFieldId, field.pageNumber);
				}
			}
		},
		onClickSave(reviewedFields) {
			this.emitter.emit('save-changes', reviewedFields);
		},
		onClickCancel() {
			this.getDocumentFields();
		},
		getDocumentFields() {
			this.documentFields = [];
			if (!_.isEmpty(this.documentRequestFields)) {
				const sectionNames = _.uniq(_.map(this.documentRequestFields, 'sectionName'));
				for (let i = 0; i < sectionNames.length; i++) {
					const sectionFields = _.filter(this.documentRequestFields, { sectionName: sectionNames[i] });
					const sectionMissingFields = _.filter(this.documentConfigurationSectionFields, (documentConfigurationSectionField) => {
						return (
							!_.includes(_.map(this.documentRequestFields, 'documentConfigurationSectionFieldId'), documentConfigurationSectionField.documentConfigurationSectionFieldId) &&
							documentConfigurationSectionField.sectionName === sectionNames[i]
						);
					});

					const maxSectionOccurrence = _.maxBy(sectionFields, 'sectionOccurrence').sectionOccurrence;
					if (maxSectionOccurrence === 1) {
						let section = {};
						section.open = false;
						section.sectionName = `${sectionNames[i]}`;
						section.fields = [];
						_.map(sectionFields, (f) => {
							section.fields.push({
								documentRequestFieldId: f.documentRequestFieldId,
								documentConfigurationSectionFieldId: f.documentConfigurationSectionFieldId,
								pageNumber: f.pageNumber,
								confidenceScore: f.confidenceScore,
								xCoordinate: f.xCoordinate,
								yCoordinate: f.yCoordinate,
								name: f.targetName,
								value: f.ocrValue,
								changed: false,
								missing: false,
							});
						});
						_.map(sectionMissingFields, (f) => {
							section.fields.push({
								documentRequestFieldId: null,
								documentConfigurationSectionFieldId: f.documentConfigurationSectionFieldId,
								pageNumber: null,
								confidenceScore: null,
								xCoordinate: null,
								yCoordinate: null,
								name: f.targetName,
								value: null,
								changed: false,
								missing: true,
							});
						});
						this.documentFields.push(section);
					} else {
						for (let j = 1; j <= maxSectionOccurrence; j++) {
							let section = {};
							section.open = false;
							section.sectionName = `${sectionNames[i]} ${j}`;
							section.fields = [];
							const sectionSequenceNumberFields = _.filter(sectionFields, { sectionOccurrence: j });
							_.map(sectionSequenceNumberFields, (f) => {
								section.fields.push({
									documentRequestFieldId: f.documentRequestFieldId,
									documentConfigurationSectionFieldId: f.documentConfigurationSectionFieldId,
									pageNumber: f.pageNumber,
									confidenceScore: f.confidenceScore,
									xCoordinate: f.xCoordinate,
									yCoordinate: f.yCoordinate,
									name: f.targetName,
									value: f.ocrValue,
									changed: false,
									missing: false,
								});
							});
							_.map(sectionMissingFields, (f) => {
								section.fields.push({
									documentRequestFieldId: null,
									documentConfigurationSectionFieldId: f.documentConfigurationSectionFieldId,
									pageNumber: null,
									confidenceScore: null,
									xCoordinate: null,
									yCoordinate: null,
									name: f.targetName,
									value: null,
									changed: false,
									missing: true,
								});
							});
							this.documentFields.push(section);
						}
					}
				}
			}
		},
	},
	watch: {
		documentRequestFields: {
			handler(documentRequestFields) {
				if (documentRequestFields) {
					this.getDocumentFields();
				}
			},
			immediate: true,
		},
	},
};
</script>
