import { nextTick } from 'vue'

const instances = new WeakMap();

function createObserver(el, vnode, modifiers, callback) {
	const observer = new IntersectionObserver((entries) => {
		const entry = entries[0];
		if (entry.isIntersecting) {
			callback();
			if (modifiers.once) {
				disconnectObserver(observer, el);
			}
		}
	});

	// Observe when element is inserted in DOM
	nextTick(() => observer.observe(el));

	return observer;
}

function disconnectObserver(observer) {
	if (observer) {
		observer.disconnect();
	}
}

function beforeMount(el, { value, modifiers }, vnode) {
	if (typeof window.IntersectionObserver !== undefined) {
		const observer = createObserver(el, vnode, modifiers, () => {
			const callback = value;
			if (typeof callback === 'function') {
				callback();
			}
		});
		instances.set(el, { observer });
	}
}

function updated(el, { value, oldValue }, vnode) {
	if (value === oldValue) {
		return;
	}

	const { observer } = instances.get(el);
	disconnectObserver(observer, el);
	beforeMount(el, { value }, vnode);
}

function unmounted(el) {
	if (instances.has(el)) {
		const { observer } = instances.get(el);
		disconnectObserver(observer, el);
		instances.delete(el);
	}
}

export default {
	beforeMount,
	updated,
	unmounted,
};
