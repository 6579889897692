<script>
import { h } from 'vue';

export default {
	name: 'ScrollingPage',
	emits: ['page-jump'],
	props: {
		page: {
			type: Object, // instance of PDFPageProxy returned from pdf.getPage
			required: true,
		},
		currentPage: {
			type: Number,
			default: undefined,
		},
		clientHeight: {
			type: Number,
			default: 0,
		},
		scrollTop: {
			type: Number,
			default: 0,
		},
	},
	data() {
		return {
			elementTop: 0,
			elementHeight: 0,
		};
	},
	computed: {
		isPageFocused() {
			return this.page.pageNumber === this.currentPage;
		},
		isElementFocused() {
			if (!this.elementHeight) {
				return;
			}

			const halfHeight = this.elementHeight / 2;
			const halfScreen = this.clientHeight / 2;
			const delta = this.elementHeight >= halfScreen ? halfScreen : halfHeight;
			const threshold = this.scrollTop + delta;

			return this.elementTop < threshold && this.bottom >= threshold;
		},
		bottom() {
			return this.elementTop + this.elementHeight;
		},
	},
	methods: {
		jumpToPage() {
			if (!this.isElementFocused && this.isPageFocused) {
				this.$emit('page-jump', this.elementTop);
			}
		},
		updateElementBounds() {
			const { offsetTop, offsetHeight } = this.$el;
			this.elementTop = offsetTop;
			this.elementHeight = offsetHeight;
		},
	},
	watch: {
		scrollTop: 'updateElementBounds',
		clientHeight: 'updateElementBounds',
		isPageFocused: 'jumpToPage',
	},
	created() {
		this.emitter.on('update-visibility', this.updateElementBounds);
	},
	mounted() {
		this.updateElementBounds();
	},
	render() {
		const { isPageFocused, isElementFocused } = this;
		return h('div', [
			this.$slots.default({ isPageFocused, isElementFocused })
		]);
	},
};
</script>
