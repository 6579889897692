<template>
	<div class="">
		<div class="flex items-center">
			<h2 class="text-gray-500 text-base font-medium uppercase tracking-wide pr-3">Document Queue</h2>
			<span class="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-yellow-500 text-white">
				{{ filteredRows.length }}
			</span>
			<span><input type="text" class="input-text ml-10" placeholder="Search for queue item" v-model="filter" /></span>
			<!-- <span><input type="number" class="input-text ml-10" placeholder="# days" v-model="maxDocumentAgeInDays" /></span> -->
		</div>
		<div class="flex flex-col mt-3">
			<div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
				<div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
					<div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
						<table class="min-w-full divide-y divide-gray-200">
							<thead class="bg-gray-50">
								<tr>
									<th v-for="header in headers" :key="header" scope="col" class="table-header">
										{{ header }}
									</th>
								</tr>
							</thead>
							<tbody>
								<tr :class="getRowColor(i)" v-for="(item, i) in filteredRows" :key="item.documentRequestId">
									<td class="table-item">
										{{ item.documentRequestSource }}
									</td>
									<td class="table-item font-medium">
										<div class="flex items-center">
											<div class="icon-gray"><PDFIcon /></div>
											<div v-if="item.documentType != null" class="mr-2">{{ item.documentType }}</div>
											<div v-else class="mr-2">Unknown</div>
										</div>
									</td>
									<td class="table-item">
										{{ item.documentAnalysisType }}
									</td>
									<td class="table-item">
										{{ item.pageCount }}
									</td>
									<td class="table-item">
										{{ `${getDateString(item.ocrProcessingCompletedDate)}` }}
									</td>
									<td class="table-item">
										{{ item.fieldCount }}
									</td>
									<td class="table-item">
										{{ item.definedFieldCount - item.fieldCount }}
									</td>
									<td class="table-item text-right font-medium">
										<div v-if="item.documentType != null" class="mr-2">
											<button @click="review(item.requestId)" class="main-btn">Review</button>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import PDFIcon from '../icons/PdfIcon.vue';
import { parseISO, format } from 'date-fns';

export default {
	name: 'DocumentQueue',
	components: {
		PDFIcon,
	},
	computed: {
		filteredRows() {
			if (this.documentRequests) {
				return this.documentRequests.filter((row) => {
					const documentRequestSource = row.documentRequestSource.toString().toLowerCase();
					const documentType = row.documentType != null ? row.documentType.toLowerCase() : null;
					const clientPostResponseStatusCode = row.clientPostResponseStatusCode != null ? row.clientPostResponseStatusCode.toLowerCase() : null;
					const searchTerm = this.filter.toLowerCase();
					return documentRequestSource.includes(searchTerm) || documentType.includes(searchTerm) || clientPostResponseStatusCode.includes(searchTerm);
				});
			} else {
				return [];
			}
		},
	},
	props: {
		documentRequests: {
			type: Array,
			required: true,
		},
	},
	data: () => {
		return {
			filter: '',
			maxDocumentAgeInDays: 1,
			headers: ['Source', 'Document Type', 'Document Analysis Type', 'Pages', 'Date Completed', 'Fields', 'Missing Fields', ''],
		};
	},
	methods: {
		getRowColor(i) {
			return i % 2 == 0 ? 'bg-white' : 'bg-gray-50';
		},
		getDateString(date) {
			if (date) {
				return format(parseISO(date), 'dd-MMM-yyyy hh:mm:ss');
			}
			return '';
		},
		review(requestId) {
			this.$router.push({ name: 'DocumentReview', query: { requestId } });
		},
	},
};
</script>
