<template>
	<div class="doc-summary">
		<div class="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
			<div class="flex items-center">
				<div class="">
					<PDFIcon class="icon-gray h-8 w-8" />
				</div>
				<div class="main-header">Document Review</div>
			</div>
			<DocumentSection
				v-for="documentField in documentFields"
				:key="documentField.sectionName"
				:documentField="documentField"
				@toggle-open="toggleOpen"
				@dbl-click="onDblClick"
				@change="onChange"
			/>
		</div>
		<div class="py-2">
			<div class="flex justify-end">
				<button @click="onClickCancel" class="alt-btn">Cancel</button>
				<button @click="onClickSave" class="main-btn">Save</button>
			</div>
		</div>
	</div>
</template>

<script>
import PDFIcon from '../icons/PdfIcon.vue';
import DocumentSection from './DocumentSection.vue';

export default {
	name: 'DocumentSummary',
	emits: ['dbl-click','save-changes','cancel-changes'],
	components: {
		PDFIcon,
		DocumentSection,
	},
	props: {
		documentFields: {
			type: Array,
			required: true,
		},
	},
	methods: {
		toggleOpen(sectionName) {
			this.documentFields.forEach((d) => {
				if (d.sectionName == sectionName) {
					d.open = !d.open;
				} else {
					d.open = false;
				}
			});
			return this.documentFields;
		},
		onChange(documentConfigurationSectionFieldId, inputValue) {
			this.documentFields.forEach((d) => {
				d.fields.forEach((f) => {
					if (f.documentConfigurationSectionFieldId == documentConfigurationSectionFieldId) {
						f.reviewValue = inputValue;
						f.changed = true;
					}
				});
			});
			return this.documentFields;
		},
		onDblClick(documentRequestFieldId, sectionName) {
			this.$emit('dbl-click', documentRequestFieldId, sectionName);
		},
		onClickSave() {
			const reviewedFields = [];
			this.documentFields.forEach((d) => {
				d.fields.forEach((f) => {
					if (f.changed == true) {
						reviewedFields.push({
							documentRequestFieldId: f.documentRequestFieldId,
							documentConfigurationSectionFieldId: f.documentConfigurationSectionFieldId,
							reviewValue: f.reviewValue,
						});
					}
				});
			});

			if (reviewedFields.length > 0) {
				this.$emit('save-changes', reviewedFields);
			}
		},
		onClickCancel() {
			this.$emit('cancel-changes');
		},
	},
};
</script>
