<template>
	<div>
		<Hero />
		<Features />
		<div ref="contact" >
			<Contact :defaultSubject="defaultSubject" />
		</div>
	</div>
</template>

<script>
import Hero from '@/components/Hero.vue';
import Features from '@/components/Features.vue';
import Contact from '@/components/Contact.vue';

export default {
	name: 'Home',
	inject: ['appInsights'],
	components: {
		Hero,
		Features,
		Contact,
	},
	data() {
		return {
			defaultSubject: '',
		};
	},
	methods: {
		scrollToContact(subject) {
			const el = this.$refs.contact;
			if (el) {
				this.defaultSubject = subject;

				el.scrollIntoView({
					behavior: 'smooth',
					block: 'center',
				});
			}
		},
	},
	created() {
		this.appInsights.trackPageView({ name: 'Home' });
		this.emitter.on('scroll-to-contact', this.scrollToContact);
	},
};
</script>
