<template>
	<div class="container">
		<canvas v-visible.once="drawPage" v-bind="canvasAttrs"></canvas>
		<div v-visible.once="highlightExtractedText" class="overlay"></div>
	</div>
</template>

<script>
import _ from 'lodash';
import visible from '@/directives/visible';

export default {
	name: 'PDFPage',
	emits: ['page-focus','error'],
	props: {
		page: {
			type: Object,
			required: true,
		},
		documentRequestFields: {
			type: Array,
			required: true,
		},
		scale: {
			type: Number,
			required: true,
		},
		isPageFocused: {
			type: Boolean,
			default: false,
		},
		isElementFocused: {
			type: Boolean,
			default: false,
		},
		selectedDocumentRequestFieldId: {
			type: Number,
			default: undefined,
		},
	},
	directives: {
		visible,
	},
	data() {
		return {
			renderTask: undefined,
		};
	},
	computed: {
		canvasAttrs() {
			const viewport = this.page.getViewport({ scale: this.scale });
			let { width, height } = viewport;
			const [pixelWidth, pixelHeight] = [width, height].map((d) => d / window.devicePixelRatio);
			const style = `width: ${pixelWidth}px; height: ${pixelHeight}px;`;
			return {
				style,
				class: 'pdf-page box-shadow',
			};
		},
	},
	methods: {
		focusPage() {
			if (!this.isPageFocused) {
				this.$emit('page-focus', this.page.pageNumber);
			}
		},
		drawPage() {
			if (this.renderTask) {
				return;
			}
			const viewport = this.page.getViewport({ scale: this.scale });
			const canvas = this.$el.children[0];
			canvas.height = viewport.height;
			canvas.width = viewport.width;

			const canvasContext = canvas.getContext('2d');
			const renderContext = { canvasContext, viewport };

			this.renderTask = this.page.render(renderContext);
			this.renderTask.promise.then().catch((response) => {
				this.destroyRenderTask();
				this.$emit('error', {
					response,
					page: this.page,
					text: `Failed to render page ${this.page.pageNumber}`,
				});
			});
		},
		highlightExtractedText() {
			const padding = 2;
			const overlay = this.$el.children[1];
			while (overlay.firstChild) {
				overlay.removeChild(overlay.lastChild);
			}

			if (!_.isEmpty(this.documentRequestFields)) {
				_.map(this.documentRequestFields, (value) => {
					if (value.pageNumber === this.page.pageNumber) {
						const x = (value.xCoordinate - padding) * this.scale;
						const y = (value.yCoordinate - padding) * this.scale;
						const width = (value.width + padding * 2) * this.scale;
						const height = (value.height + padding * 2) * this.scale;
						const highlightDiv = this.createHighlightDiv(
							value.documentRequestFieldId,
							x,
							y,
							width,
							height,
							`${value.targetName}, Confidence Score: ${value.confidenceScore * 100}%`
						);
						overlay.appendChild(highlightDiv);
					}
				});
			}
			if (!_.isNil(this.selectedDocumentRequestFieldId)) {
				for (let i = 0; i < overlay.children.length; i++) {
					if (overlay.children[i].id === this.selectedDocumentRequestFieldId.toString()) {
						overlay.children[i].style.border = '2px solid red';
						overlay.children[i].scrollIntoView({
							behavior: 'smooth',
							block: 'center',
						});
					} else {
						overlay.children[i].style.border = 'none';
					}
				}
			} else {
				for (let i = 0; i < overlay.children.length; i++) {
					overlay.children[i].style.border = 'none';
				}
			}
		},
		highlightExtractedTextField() {
			const overlay = this.$el.children[1];
			if (!_.isNil(this.selectedDocumentRequestFieldId)) {
				for (let i = 0; i < overlay.children.length; i++) {
					if (overlay.children[i].id === this.selectedDocumentRequestFieldId.toString()) {
						overlay.children[i].style.border = '2px solid red';
						overlay.children[i].scrollIntoView({
							behavior: 'smooth',
							block: 'center',
						});
					} else {
						overlay.children[i].style.border = 'none';
					}
				}
			} else {
				for (let i = 0; i < overlay.children.length; i++) {
					overlay.children[i].style.border = 'none';
				}
			}
		},
		createHighlightDiv(id, left, top, width, height, text) {
			const highlightDiv = document.createElement('div');
			highlightDiv.setAttribute('id', id);
			highlightDiv.setAttribute('class', 'highlight');
			highlightDiv.setAttribute('style', 'border-radius: 2px;left:' + left + 'px; top:' + top + 'px;' + 'width:' + width + 'px; height:' + height + 'px;');
			highlightDiv.setAttribute('title', text);
			return highlightDiv;
		},
		updateVisibility() {
			this.emitter.emit('update-visibility');
			this.highlightExtractedText();
		},
		destroyPage(page) {
			if (page) {
				page._destroy();
			}

			this.destroyRenderTask();
		},
		destroyRenderTask() {
			if (!this.renderTask) {
				return;
			}

			this.renderTask.cancel();
			delete this.renderTask;
		},
	},
	watch: {
		scale: 'updateVisibility',
		page(_newPage, oldPage) {
			this.destroyPage(oldPage);
		},
		isElementFocused(isElementFocused) {
			if (isElementFocused) {
				this.focusPage();
			}
		},
		documentRequestFields: 'highlightExtractedText',
		selectedDocumentRequestFieldId: 'highlightExtractedTextField',
	},
	beforeUnmount() {
		this.destroyPage(this.page);
	},
};
</script>
